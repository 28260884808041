import FullCalendar from '@fullcalendar/react';
import React, { useCallback } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axiosInstance from '@src/api/axiosInstance';
import { Loading } from '@src/Loading';

const Calendar = () => {
	const [events, setEvents] = React.useState<EventInterface[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const getOrganizationIDfromQueryParam = () => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get('organizationID');
	};
	const fetchEvents = useCallback(async () => {
		setIsLoading(true);
		try {
			const organizationID = getOrganizationIDfromQueryParam();
			let response: any;
			if (!organizationID) {
				response = await axiosInstance.get(`/events`);
			} else {
				response = await axiosInstance.get(`/organizations/${organizationID}/events`);
			}

			setEvents(response.events);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}, []);


	React.useEffect(() => {
		fetchEvents();
	}, [fetchEvents]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<div className="event-calendar">
			<FullCalendar
				plugins={[dayGridPlugin]}
				initialView="dayGridMonth"
				locale={'fi'}
				firstDay={1}
				displayEventTime={false}
				events={events.map(event => ({
					title: event.name,
					date: event.date,
				}))}
			/>
		</div>
	);
};

export default Calendar;
