import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import Calendar from './calendar/Calendar';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find root element');
const root = ReactDOM.createRoot(rootElement);

root.render(<Calendar />);
